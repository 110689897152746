export const SUPPORTED_CARDS_FOR_SA = ['VISA', 'MASTERCARD'];

export const SUPPORTED_CARDS_FOR_VN = ['VISA', 'MASTERCARD'];

export const PAYU_SUPPORTED_CARDS_BY_CURRENCY: { [key: string]: string[] } = {
  ZAR: SUPPORTED_CARDS_FOR_SA,
  VND: SUPPORTED_CARDS_FOR_VN
};

export const CHECKOUT_REDIRECTION: string = 'CHECKOUT_REDIRECTION';

export const CHECKOUT_FORM: string = 'CHECKOUT_FORM';

const getBooleanEnvVar = (
  name: string,
  defaultValue: boolean = false
): boolean => {
  const value = process.env[name];
  return value ? value.toLowerCase() === 'true' : defaultValue;
};

const splitStringExceptToBeRemoved = (str: string): string[] => {
  return str !== '' ? str.split(',') : [''];
};

const ENABLE_CHECKOUT_REDIRECTION_FLOW = getBooleanEnvVar(
  'ENABLE_CHECKOUT_REDIRECTION_FLOW'
);

const ENABLE_CHECKOUT_REDIRECTION_FLOW_BY_MERCHANT = getBooleanEnvVar(
  'ENABLE_CHECKOUT_REDIRECTION_FLOW_BY_MERCHANT'
);

const CHECKOUT_REDIRECT_FLOW_MID: string[] = splitStringExceptToBeRemoved(
  process.env.CHECKOUT_REDIRECT_FLOW_MID || ''
);

export const CARD_CHECKOUT_FLOW_BY_CURRENCY: { [key: string]: string } = {
  ZAR: CHECKOUT_FORM,
  VND: ENABLE_CHECKOUT_REDIRECTION_FLOW ? CHECKOUT_REDIRECTION : CHECKOUT_FORM
};

export const getCheckoutFlowByCurrency = (
  currency: string,
  merchantId: string
): string => {
  const checkoutFlow = CARD_CHECKOUT_FLOW_BY_CURRENCY[currency.toUpperCase()];

  if (checkoutFlow === CHECKOUT_FORM) {
    return checkoutFlow;
  } else if (!ENABLE_CHECKOUT_REDIRECTION_FLOW_BY_MERCHANT) {
    return checkoutFlow;
  } else if (CHECKOUT_REDIRECT_FLOW_MID.includes(merchantId)) {
    return checkoutFlow;
  } else {
    return CHECKOUT_FORM;
  }
};
